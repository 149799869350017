import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
} from '@angular/core';
import * as DeviceDetector from 'device-detector-js';
import { DeviceDetectorResult } from 'device-detector-js';
import { Observable, Subject, delay, map, switchMap, takeUntil, timer } from 'rxjs';
import { AppStoreFacade } from './+state/app-store.facade';
import { ModalService } from '@components/common/modal-design/modal.service';
import { CommonService } from './services/common/common.service';
import { AnalyticsService } from './services/common/analytics.service';
import { FirebaseMessagingService } from './services/pushNotification/firebase-messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  isLoading: boolean = false;
  data: any;
  deviceId: any;
  device: DeviceDetectorResult | undefined;
  deviceDetector = new DeviceDetector();
  options: google.maps.MapOptions = {
    mapId: '7a96fb338796ab99',
    center: { lat: 28.7, lng: 77.1 },
    zoom: 4,
    disableDefaultUI: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    restriction: {
      latLngBounds: {
        north: 37.6,
        south: 6.7,
        west: 68.7,
        east: 97.25,
      },
      strictBounds: true,
    },
  };

  markers: any = [
    {
      position: { lat: 28.7, lng: 77.1 },
      label: {
        color: 'blue',
      },
      title: 'Marker Title',
      options: {
        animation: google.maps.Animation.DROP,
      },
    },
  ];

  optionss: any = {
    componentRestrictions: {
      country: ['IN'],
    },
  };
  formattedaddress: any;
  isLocationEnabled: any = false;

  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();
  deviceToken: any;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _modalService: ModalService,
    private _analyticsService: AnalyticsService,
    private _appStoreFacade: AppStoreFacade,
    private _commonService: CommonService,
    private _ngZone: NgZone,
    private messagingService: FirebaseMessagingService
  ) {
    try {
      this.device = this.deviceDetector.parse(window.navigator.userAgent);
    } catch (error) {}
    this._analyticsService.generateDeviceId();

    this._appStoreFacade.isLoading$
      .pipe(takeUntil(this._unsubscribe$), delay(0))
      .subscribe((visibility: any) => {
        this.isLoading = visibility;
        this._cdr.markForCheck();
      });

    this._appStoreFacade.deviceId$
      .pipe(takeUntil(this._unsubscribe$), delay(0))
      .subscribe((deviceId: any) => {
        this.deviceId = deviceId;
        this._cdr.markForCheck();
      });

    this._appStoreFacade.deviceToken$
      .pipe(takeUntil(this._unsubscribe$), delay(0))
      .subscribe((id: string) => {
        this.deviceToken = id;
        this._cdr.markForCheck();
      });

      this._appStoreFacade.cart$
  .pipe(
    takeUntil(this._unsubscribe$),
    delay(0),
    switchMap((id: any) => {
      if (id?.orderItems?.length >= 1) {
        return timer(180000).pipe(
          switchMap(() => {
            this.cartNotifications();
            return []; 
          })
        );
      }
      return [];
    })
  )
  .subscribe();
    this._appStoreFacade.updateDeviceDetails(this.device);
  }

  ngOnInit(): void {
    this.messagingService.requestPermission();
    this.messagingService.listenForMessages();
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
      })
      .catch((err) => {
        console.error('Service Worker registration failed:', err);
      });
    this.isGeolocationEnabled()
      .then((isEnabled) => {
        this.isLocationEnabled = isEnabled;
      })
      .catch((error) => {
        this.isLocationEnabled = false;
      })
      .finally(() => {
        this._cdr.markForCheck();
      });
  }
  cartNotifications() {
    const body = {
      registrationTokens: [this.deviceToken],
      title: 'Hello !',
      body: 'Your items in cart are waiting for you',
    };
    this.messagingService.pushNotification(body).subscribe((gigi: any) => {
      return gigi;
    });
  }
  accessforLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {},
        (error) => {}
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
  getCurrentLocation() {
    this._commonService
      .getCurrentPosition()
      .then((position) => {
        this._ngZone.run(() => {
          let location = {
            latitude: position.lat,
            longitude: position.lon,
          };
          this._appStoreFacade.updateDeliveryLocation(location);
          this._modalService.close('locationPopup');
          this._cdr.markForCheck();
        });
      })
      .catch((error) => {
        this._ngZone.run(() => {
          console.error(error);
          alert(
            'Geolocation is not supported by this browser or an error occurred.'
          );
        });
      });
  }
  getPlaceName(lat: number, lng: number) {
    return this._commonService.getPlaceName(lat, lng);
  }

  addressChange(address: any) {
    this.formattedaddress = address.formatted_address;
    if (address.geometry && address.geometry.location) {
      this.updateMapLocation(
        address.geometry.location.lat(),
        address.geometry.location.lng(),
        address.name
      );
    }
  }

  updateMapLocation(lat: number, lng: number, name: string) {
    let location = { latitude: lat, longitude: lng };
    this._appStoreFacade.updateDeliveryLocation(location); // Ensure this updates the store

    // Optionally update formattedaddress if needed
    this.formattedaddress = name;

    this.options = { ...this.options, center: { lat, lng }, zoom: 15 };
    this.markers = [
      {
        position: { lat, lng },
        label: { color: 'blue', text: name },
        title: name,
        options: { animation: google.maps.Animation.DROP },
      },
    ];
    this._modalService.close('locationPopup');
  }

  isGeolocationEnabled(): Promise<boolean> {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          () => resolve(true),
          () => resolve(false)
        );
      } else {
        resolve(false);
      }
    });
  }
}
