<router-outlet></router-outlet>


<div class="loading-screen" *ngIf="isLoading">
    <div class="loading-screen-inner d-flex flex-column justify-content-center">
        <img src="./../assets/logoLoader.gif" class="img-fluid w-100 object-fit-contain">
    </div>
</div>
<modal-design [disableBackdropClose]="true"
    customClasses="min-vh-30 my-0 mx-4 position-relative border-rounded overflow-auto font-light font-base popup-responsive"
    id="locationPopup">
    <div class=" get-address-container text-center py-2">
        <div class="form-header-container align-items-center my-3">
            <h4 class="heading font-bold">Your Location</h4>
        </div>
        <div class="form-main-container">
            <div class="form-inner-container">
                <form class="form-main">
                    <div class="map-container">
                        <google-map height="250px" [options]="options">
                            <map-marker *ngFor="let marker of markers" [position]="marker.position"
                                [label]="marker.label" [title]="marker.title" [options]="marker.options">
                            </map-marker>
                        </google-map>
                    </div>
                    <div class="form-main-inner text-center mt-2">
                        <div *ngIf="!isLocationEnabled">
                            <svg class="mt-2" width="63" height="63" viewBox="0 0 63 63" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M35.9991 28.7143L29.0886 21.8039C29.4331 21.5886 29.7991 21.4379 30.1866 21.3518C30.5741 21.2657 30.9831 21.2227 31.4136 21.2227C32.8345 21.2227 34.0508 21.7286 35.0626 22.7404C36.0744 23.7522 36.5803 24.9685 36.5803 26.3893C36.5803 26.8199 36.5373 27.2289 36.4511 27.6164C36.365 28.0039 36.2143 28.3699 35.9991 28.7143ZM55.148 58.907C54.1392 59.9159 52.4896 59.9298 51.4807 58.921L40.9074 48.3477C39.5727 49.8116 38.1195 51.297 36.548 52.8039C34.9765 54.3109 33.265 55.8393 31.4136 57.3893C24.4817 51.4907 19.3043 46.0227 15.8814 40.9852C12.4584 35.9477 10.747 31.2546 10.747 26.906C10.747 25.5282 10.8546 24.215 11.0699 22.9664C11.2852 21.7178 11.5866 20.5338 11.9741 19.4143L3.98409 11.4244C2.97523 10.4155 2.98916 8.76589 3.99802 7.75703C5.00687 6.74818 6.65648 6.73424 7.66533 7.7431L55.1619 55.2397C56.1708 56.2486 56.1569 57.8982 55.148 58.907ZM47.4303 40.1456C48.9803 37.7345 50.1428 35.4418 50.9178 33.2675C51.6928 31.0931 52.0803 28.9727 52.0803 26.906C52.0803 20.4477 50.0029 15.3025 45.848 11.4706C41.6932 7.63863 36.8817 5.72266 31.4136 5.72266C28.8734 5.72266 26.4515 6.14245 24.148 6.98203C21.8445 7.82161 19.7671 9.03793 17.9157 10.631L21.597 14.3122C22.9317 13.1928 24.4279 12.3424 26.0855 11.7612C27.7432 11.1799 29.5192 10.8893 31.4136 10.8893C35.7623 10.8893 39.4327 12.3855 42.4251 15.3779C45.4175 18.3702 46.9136 22.2129 46.9136 26.906C46.9136 28.2838 46.6553 29.7584 46.1386 31.33C45.622 32.9015 44.8039 34.5914 43.6845 36.3997L47.4303 40.1456ZM31.4136 50.5435C32.5331 49.5532 33.5772 48.5737 34.5459 47.6049C35.5147 46.6362 36.4296 45.6782 37.2907 44.731L16.172 23.6122C16.0859 24.172 16.0213 24.7209 15.9782 25.2591C15.9352 25.7973 15.9136 26.3463 15.9136 26.906C15.9136 29.9629 17.1838 33.4612 19.7241 37.4008C22.2643 41.3404 26.1609 45.7213 31.4136 50.5435Z"
                                    fill="#FCE672" />
                            </svg>
                            <h4>Location Permission is off</h4>
                            <span>Enable your location permission for better delivery</span>
                        </div>
                        <div *ngIf="isLocationEnabled">
                            <div class="col-lg-12 col-sm-12 my-3 form-field">
                                <button type="button" class="full-width-button yellow-button text-secondary wd-40" (click)="getCurrentLocation()">Use My Current Location</button>
                            </div>
                            <div class="col-lg-12 col-sm-12 pb-1 form-field">
                                <input ngx-google-places-autocomplete [options]='optionss' (onAddressChange)="addressChange($event)" class="full-width-input wd-40" placeholder="Search Your Location Manualy" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 my-3 form-field" *ngIf="!isLocationEnabled">
                            <button type="button" class="full-width-button yellow-button text-secondary wd-40" (click)="accessforLocation()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</modal-design>

<modal-design [disableBackdropClose]="true" 
    customClasses="min-vh-30 my-0 w-sm-100 h-xs-100 mx-0 wd-40 rounded-xs-0 position-relative border-rounded overflow-auto font-light font-base popup-responsive"
    id="loginPopup">
    <app-signin-check></app-signin-check>
</modal-design>
