import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as firebase from 'firebase/messaging';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { environment } from '@env/environment';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  message: any = null;
  deviceToken: string | null = null;
  apiKey = environment.OUMS_API_URL;
  vapidKey =
    'BGPB4oqkAsWBo1B5j7LWmsi8nWKJy4qVzOmecEdilNw7gkAj1VnfVv2JTcLw1-BEMOs3YB_DkdrmZcRSq7XZ9Nk';
  constructor(
    private afMessaging: AngularFireMessaging,
    private _appStoreFacade: AppStoreFacade,
    private _http: HttpClient
  ) {
    initializeApp(environment.firebase);
  }
  async requestPermission() {
    const messaging = getMessaging();
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      getToken(messaging, { vapidKey: this.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            this.deviceToken = currentToken;
            this._appStoreFacade.addDeviceToken(currentToken);
          } else {
          }
        })
        .catch((err) => {
        });
    } else {
    }
  }
  pushNotification(data: any) {
    return this._http.post(`${this.apiKey}/push/notification`, data);
  }
  listenForMessages() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      if (Notification.permission === 'granted') {
        const { title, body } = payload.notification;
        const notification = new Notification(title, {
          body,
          icon: './../../../assets/images/fashquik_logo.svg',
        });
        notification.onclick = () => {
          window.open('https://web.stg.fashquik.com/', '_blank');
        };
      }
    });
  }
}
