<div class="login-container d-flex justify-content-center p-3 p-md-5">
  <div class="login-inner-container position-relative py-2">
   
    <div *ngIf="loginStep==2"
      class="position-absolute top-0 start-0 close-btn w-75 d-flex justify-content-start align-self-start d-lg-none"
      (click)="gotoLogin()"><img src="assets/icons/icons/leftArrow.svg"></div>
    <div class="login-top-section d-flex justify-content-center pb-2 " *ngIf="loginStep == 1">
      <img class="w-50 wd100" src="assets/images/login_vector.svg" />
    </div>
    <div class="login-middle-section">
      <div class="my-3" *ngIf="loginStep == 1 || loginStep ==2">
        <span class="mb-2 fs-5 d-block fw-bold">{{displayTitle().title}}</span>
        <span class="text-muted fw-bold">{{displayTitle().description}}</span>
      </div>

      <ng-container *ngIf="loginStep == 1">
        <form [formGroup]="loginForm">
          <div class="form-group d-flex align-items-center fs-7">
            <select class="select-control pe-2" id="countryCode" name="countryCode">
              <option value="+91">+91</option>
            </select>
            <hr>
            <input type="number" class="input-control border-0 w-100" formControlName="mobileNumber"
              placeholder="Enter Number" required>
          </div>
          <h6 class="fs-8 mt-1 text-danger fw-bold position-absolute" *ngIf="loginForm.get('mobileNumber').invalid && (loginForm.get('mobileNumber').touched)">Enter Valid Phone Number</h6>
          <span class="text-gray font-xs text-muted my-4 d-block fs-8 w-75 text-b text-body-tertiary">A 6 digit OTP will be sent via SMS to verify
            your mobile number!</span>
          <div class="form-action">
            <button type="button" (click)="sendOTP()"
              class="btn-common-primary w-100 br-1 fs-8 border-0 font-regular button-common p-1 py-2 my-3 fw-bold text-secondary">Send
              OTP</button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="loginStep == 2">
        <div class="otp-input-group my-4 d-flex justify-content-center">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
        </div>
        <h5 class="fs-7 fw-bold text-secondary" *ngIf="isButtonDisabled">Resend OTP in : {{ countdown }}</h5>
        <a *ngIf="!isButtonDisabled" class="fs-7 fw-bold text-secondary cPr" (click)="sendOTP()">Resend OTP</a>
        <button type="button"
          class="btn-common-primary w-100 br-1 fs-8 border-0 font-regular button-common p-1 py-2 my-3 fw-bold text-secondary"
          (click)="verifyOtp()" >VERIFY OTP</button>
        <!-- <button type="button"
          class="btn-common-primary w-100 br-1 fs-8 border-0 font-regular button-common p-1 py-2 my-3 fw-bold text-secondary"
          (click)="sendOTP()" *ngIf="!isButtonDisabled">RESEND OTP</button> -->
      </ng-container>

      <ng-container *ngIf="loginStep == 3">
        <div class="otp-input-group d-flex flex-column justify-content-center align-items-center">
          <img class="successIcon img-fluid d-none d-md-block" src="assets/icons/successIcon.svg">
          <img class="successMobileIcon img-fluid d-block d-md-none" src="assets/icons/successMobileIcon.svg">
          <div class="d-flex flex-column justify-content-center align-items-center py-3">
            <h5 class="fs-5 fw-bold py-2">Welcome {{userName}}</h5>
            <p class="fs-8 text-body-tertiary fw-bold text-center">Congratulations! You have been successfully
              authenticated</p>
          </div>
        </div>

        <button type="button"
          class="btn-common-primary w-100 br-1 fs-8 border-0 font-regular button-common p-1 py-2 my-3 fw-bold text-secondary"
          (click)="closeModal()">CONTINUE</button>
      </ng-container>
    </div>
    <div class="login-bottom-section text-center d-flex align-self-center" *ngIf="loginStep == 1">
      <span class="text-secondary font-sm fs-8 font-xs ">By continuing, you agree to our <a href="https://termsandconditions.fashquik.com" target="_blank">Terms of
          Use</a> & <a href="https://privacypolicy.fashquik.com" target="_blank">Privacy Policy</a></span>
    </div>
    <span class="text-body-tertiary text-center fs-8 mt-3">Powered by <strong >KAPIZ Innovators Pvt Ltd</strong></span>
  </div>
</div>
