import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ContentType } from '@core/enum/content-type.enum';
import { environment } from '@env/environment.prod';
import { environment as env} from '@env/environment';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  user: any;
  feedSlug: string = '';
  tagId: string = '';
  CONTENT_TYPE = ContentType;
  userToken: any;
  private apiUrl = {
    oums: env.OUMS_API_APP_URL,
    pms:env.PMS_UPLOADIMAGE_URL
  }
  currentLocationName: Subject<any> = new Subject<any>();
  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _appStoreFacade: AppStoreFacade,
    private _http: HttpClient
  ) { 
    this._appStoreFacade.user$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user: any) => {
        this.user = user;
      });

      this._appStoreFacade.userToken$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((userToken: any) => {
        this.userToken = userToken;
      });
  }

  get imageBaseUrl() {
    return environment.IMAGE_DOMAIN;
  }

  get isUserLoggedIn() {
    return this.user?.userId;
  }

  getAuthToken() {
    return this.user?.token || this.userToken;
  }
applyFilters(data:any,sortBy:any){
  const apiUrl = `${this.apiUrl.pms}/product/filter-product?sortBy=${sortBy}`;
  return this._http.post(`${apiUrl}`,data);
}
addRecentProducts(data:any){
  const apiUrl= `${this.apiUrl.oums}/user/add-recent-product`
  return this._http.post(apiUrl,data,
  {
    headers: this.getAuthToken(),
  });
}
getRecentProducts(){
  const apiUrl= `${this.apiUrl.oums}/user/get-recent-product`
  return this._http.get(apiUrl,
  {
    headers: this.getAuthToken(),
  });
}
  getCurrentPosition(): Promise<{ lat: number, lon: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position?.coords?.latitude,
              lon: position?.coords?.longitude
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  getPlaceName(lat: any, lng: any){
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${environment.GOOGLE_MAP_KEY}`;
    return this._http.get<any>(url).pipe(
      map(response => {
        if (response?.results?.length > 0) {
          return response.results;
        } else {
          throw new Error('No address found');
        }
      })
    );
  }
  pageName(contentType: any) {
    let page_name = '';
    switch (contentType) {
      case this.CONTENT_TYPE.PRODUCT:
        page_name = 'product_detail';
        break;
      case this.CONTENT_TYPE.VENDOR:
        page_name = 'vendor_detail';
        break;
      default:
        break;
    }
    return page_name;
  }

  copyToClipboard(text: any) {
    const textToCopy = text;

    if (textToCopy) {
      navigator?.clipboard?.writeText(textToCopy).then(() => {
      }).catch(err => {
      });
    }
  }
  searchProduct(query: string, options: { limit?: number } = {}): Observable<any> {
    const apiUrl = `${this.apiUrl.pms}/product/search-product`;
    let params = `q=${query}`;
    if (options.limit) {
      params += `&limit=${options.limit}`;
    }
    return this._http.get(`${apiUrl}?q=${query}`);
  }
}
