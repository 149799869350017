export const environment = {
    production: true,
    APP_URL: 'https://fashquik.com',
    IMAGE_DOMAIN: 'http://nextcloud.fashquik.com',
    WMS_API_URL: 'https://wms-api.fashquik.com/api/core',
    WMS_API_APP_URL: 'https://wms-api.fashquik.com/api/app',
    OUMS_API_APP_URL: 'https://oums-api.fashquik.com/api/app',
    OUMS_API_URL: 'https://oums-api.fashquik.com/api',
    PMS_API_APP_URL: 'https://pms-api.fashquik.com/api/app',
    GOOGLE_MAP_KEY: 'AIzaSyAto-YQGOiLk7pD0T8GTeTJJHBGaOrQHI8',
    razorPe_key:'rzp_live_qGFWdcj4XjwZql' ,  
    PMS_UPLOADIMAGE_URL:'https://pms-api.fashquik.com/api',
    firebase: {
        apiKey: "AIzaSyDMMofaDPDtVOqMY0ukYAfB9DOfoAP9DSs",
        authDomain: "notification-a171c.firebaseapp.com",
        projectId: "notification-a171c",
        storageBucket: "notification-a171c.appspot.com",
        messagingSenderId: "893163002266",
        appId: "1:893163002266:web:3ef7f8805ab59acb58c223",
        measurementId: "G-SDWPEVHZMN",
    }
}