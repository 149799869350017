import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwiperModule } from 'swiper/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appFeatureKey, appReducer } from './+state/app.reducer';
import { storageMetaReducer } from './+state/app.storage.reducer';
import { AppStoreFacade } from './+state/app-store.facade';
import { AppEffects } from './+state/app.effect';
import { ModalDesignModule } from '@components/common/modal-design/modal-design.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SigninCheckComponent } from '@pages/auth/signin-check/signin-check.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
@NgModule({
  declarations: [
    AppComponent,
    SigninCheckComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    HttpClientModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    GooglePlaceModule,
    SwiperModule,
    ModalDesignModule,
    NgOtpInputModule,
    FormsModule,
    StoreModule.forFeature(appFeatureKey, appReducer, {
      metaReducers: [storageMetaReducer],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AppStoreFacade,
    AppEffects,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
